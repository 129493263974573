<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <StatisticalSearch :searchType="searchType"
                         @getBerthInfo="getBerthInfo"
                         @getTableData="getTableData"></StatisticalSearch>
      <div class="line">
        <LineChart></LineChart>
      </div>
      <div class="circular">
        <el-row :gutter="20"
                class="list"
                style="margin-right: 0;margin-left: 0;">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <div class="parkStatistic parkStatisticbg1">
                <!-- 不知哪个NC取得名字  berthRate==周转次数 -->
                <span v-text="(berthInfo.berthRate * 1).toFixed(2)"></span>
              </div>
              <h2>日均泊位周转次数</h2>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <div class="parkStatistic parkStatisticbg2">
                <!-- 不知哪个NC取得名字  berthTime==泊位时长利用率 -->
                <span v-text="(berthInfo.berthTime * 100).toFixed(2)"></span>
              </div>
              <h2>泊位时长利用率</h2>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <div class="parkStatistic parkStatisticbg3">
                <span v-text="(berthInfo.parkTime * 1).toFixed(2)"></span>
              </div>
              <h2>平均停车时长</h2>
            </div>
          </el-col>

        </el-row>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table 
                  v-loading="loading"
                  :data="tableData"
                  style="width: 100%; font-size: 12px; text-align:center">
          <el-table-column :prop="item.prop"
                           :label="item.label"
                           align='center'
                           :width="item.width"
                           v-for="item in tableCols"
                           :key="item.prop"
                           :formatter="item.formatter"></el-table-column>
        </el-table>
      </div>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination v-if="total != 0"
                         @current-change="handleCurrentChange"
                         :current-page="pageNum"
                         :page-size="pageSize"
                         layout="total, prev, pager, next, jumper"
                         :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  // getDt,
  // setIndex
} from '../../../common/js/public.js'
import LineChart from '../../../components/lineChart/lineChart4';
import CircularChart from '../../../components/circularChart/circularChart2';
import AreaChart from '../../../components/areaChart/areaChart';
import StatisticalSearch from '../../../components/statisticalSearch/statisticalSearch';
export default {
  name: 'parkingHistorydata',
  data () {
    return {
      tableData: [],
      page: 1,
      pageNum: 15,
      total: 0,
      berthInfo: { berthTime: 0, berthRate: 0, parkTime: 0 },
      searchType: 'historyData',
      loading: false,
      type1: "c3",
      type2: "c4",
      tableCols: [
        {
          prop: 'dataDate',
          label: '日期'
        },
        {
          prop: 'parkIn',
          label: '入场次数'
        },
        {
          prop: 'parkOut',
          label: '出场次数'
        }
      ]
    }
  },
  methods: {
    getBerthInfo (data) {
      if (data.berthRate) {
        this.berthInfo = data
      } else {
        this.berthInfo = { berthRate: 0, berthTime: 0, parkTime: 0 }
      }
    },
    getTableData (data) {
      this.tableData = data;
    }
  },
  components: {
    LineChart,
    StatisticalSearch
  },
  beforeDestroy () {
  },

  created () {
  },
  computed: {},
  mounted () {

  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.tableWrapper
  margin 40px 0
.parkStatisticbg1
  background #68ce62
.parkStatisticbg2
  background #e6a23c
.parkStatisticbg3
  background #3a8ee6
.parkStatistic
  width 150px
  height 150px
  line-height 150px
  border-radius 50%
  text-align center
  display inline-block
  margin-bottom 40px
  color #fff
  span
    font-size 40px
.breadcrumb
  height 35px
.content
  background #FFFFFF
  overflow hidden
  .searchWrapper
    overflow hidden
    background #EFF2F7
    padding 22px 22px 0
    margin-bottom 20px
  .list
    margin-top 20px
    .el-col
      text-align center
      padding-bottom 18px
      .grid-content
        padding-top 15px
        height 100%
      h2
        font-size 18px
        text-align center
</style>
