<template>
  <div class="lineGroup">
    <div id="lineGraph">
    </div>
  </div>
</template>
<script>
// import { dateFormat } from "@/common/js/public"

export default {
  data () {
    return {
      cityNameList: ['进场'],
      dateList: [0],
      dataDetailList: [0]
    }
  },
  methods: {
    drawGraph () {
      let that = this;
      let lineGraph = this.$echarts.init(document.getElementById('lineGraph'));
      let option = {
        title: {
          text: '车流量趋势'
        },
        tooltip: {
          trigger: 'axis',
          formatter: "日期 : {b}<br/>次数 : {c}"
        },
        legend: {
          data: that.cityNameList,
          orient: 'horizontal',
          type: 'scroll',
          bottom: -5,
          width: '80%',
          selectedMode: 'multiple'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '10%',
          containLabel: true
        },
        toolbox: {

        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: that.dateList,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          position: 'left',
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          }
        },
        series: { name: '进场', type: 'line', data: this.dataDetailList }
      };
      lineGraph.setOption(option, true);
    }
  },
  mounted () {
    this.drawGraph();
  },
  created () {
    this.$EventBus.$on('getParkStatisticData', (data) => {
      if (data.length > 0) {
        this.dateList = [];
        this.dataDetailList = [];
        for (var i = 0; i < data.length; i++) {
          this.dateList.push(data[i].dataDate)
          this.dataDetailList.push(data[i].parkIn)
        }
        this.drawGraph();
      }
    })
  }
}
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.lineGroup
  #lineGraph
    height 400px
  .region
    text-align center
</style>
