<template>
  <div :id="type"
       class="circularChart">
    <!-- {{type}} -->
  </div>
</template>
<script>
//  
export default {
  props: {
    type: {
      default: 'c1'
    },
    berthOccupy: {
      type: Object,
      default: function () {
        return {}
      }
    },
    memberCarNumber: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      berthList: [{ name: '空闲泊位数', value: [0] }, { name: '占用泊位数', value: [0] }],
      memberCarNumberList: [{ name: '会员', value: [0] }, { name: '非会员', value: [0] }],
      tipTitle: ''
    }
  },
  watch: {
    berthOccupy: {
      handler (value) {
        this.berthList = [];
        this.berthList.push({ name: '空闲泊位数', value: [value.freeNumber] })
        this.berthList.push({ name: '占用泊位数', value: [value.occupyNumber] })
        this.drawGraph();
      },
      deep: true
    },
    memberCarNumber: {
      handler (value) {
        this.memberCarNumberList = [];
        this.memberCarNumberList.push({ name: '会员', value: [value.membarCarNumber] })
        this.memberCarNumberList.push({ name: '非会员', value: [value.nonmemberCarNumber] })
        this.drawGraph();
      },
      deep: true
    }
  },
  methods: {
    drawGraph () {
      let that = this;
      let circularChart = this.$echarts.init(document.getElementById(this.type));
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        // legend: {
        //   type: 'scroll',
        //   orient: 'vertical',
        //   right: 10,
        //   top: 40,
        //   data: ['空闲泊位数', '占用泊位数']
        // },
        series: [
          {
            name: this.tipTitle,
            type: 'pie',
            radius: ['50%', '70%'],
            center: ['50%', '50%'],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: 'center'
              },
              emphasis: {
                show: false,
                textStyle: {
                  fontSize: '20',
                  fontWeight: 'bold'
                }
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: that.type == "c3" ? that.berthList : that.memberCarNumberList
          }
        ]
      };
      circularChart.setOption(option, true);
    }
  },
  mounted () {
    this.drawGraph();
  },
  created () {
    if (this.type == "c3") {
      this.tipTitle = "日均泊位周转次数"
    } else if (this.type == "c4") {
      this.tipTitle = "泊位时长利用率"
    } else {
      this.tipTitle = "平均停车时长"
    }
  }
}
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.circularChart
  width 100%
  height 300px
  overflow hidden
</style>
